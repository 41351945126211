<template>
  <section class="connecting-page">
    <p>Authorising Email address</p>
  </section>
</template>
<script>
import {authStore} from '@/store/__STORE_auth';
import {mainStore} from '@/store/__STORE_main';
export default {
  props: {
    code: {
      type: String,
      default: null,
    },
  },
  computed: {
    authStatus() {
      return mainStore.status;
    },
  },
  created() {
    this.emailVerify();
  },
  methods: {
    emailVerify() {
      authStore.verifyEmail(this.code)
          .then((req) => {
            if (this.authStatus === 'success') {
              this.$toast.success('Email address sucessfully verified, please login.');
              setTimeout(() => {
                this.$router.push({name: 'login'});
              }, 1000);
            }
          })
          .catch((err) => {
            if (err.response.status == 400) {
              this.$toast.error('No verification could be found.');
              this.$router.push({name: 'need-to-verify', params: {error: 'verificationFailed'}});
            } else if (err.response.status == 403) {
              this.$toast.success('This verification has already been used, please login.');
              this.$router.push({name: 'dashboard'});
            } else {
              this.$toast.error('Your email could not be verified.');
              this.$router.push({name: 'need-to-verify', params: {error: 'verificationFailed'}});
            }
          });
    },
  },
};
</script>

<style lang="scss" scoped>
  .connecting-page {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;

    > p {
      position: relative;
      top: 4.5rem;
    }
  }
</style>
